   
    
body {
  background-color: black;
  color: white;
}
@media screen and (prefers-color-scheme: light) {
  body {
    background-color: white;
    color: black;
  }
}
.login-form {
    width: 500px;
    height: auto;
display: flex;
flex-direction: column;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 13px;
    align-items: center;
    padding: 20px;
    background-color: rgb(0, 0, 0);
    color: white;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form input[type='email'],
  .login-form input[type='password'] {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 35px;
    justify-content: center;
    padding: 10px;
    margin-bottom: 25px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-form input[type='submit'] {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 40px;
  }
  
  .login-form button:hover {
    background-color: #45a049;
  }
  
  @media screen and (prefers-color-scheme: light) {
    body {
      background-color: white;
      color: black;
    }
  }