.Footer{
    background-color: black;
}
.footer-sections{
    height: 200px;
    width: 100%;
    display: flex;
    padding: 30px;
}
.footer-section{
    width: 30%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}
.footer-content a{
    text-decoration: none;
    color: azure;
}
.footer-content a:hover{
    color: blue;
}