.App{
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 12px;
    scroll-behavior: smooth;
    transition: 0.5s;
    background-color: black;
    background-image: url(https://images.unsplash.com/photo-1581215524789-83361d941827?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: black;
    color: white;
  }
h3{
  border: none
}
p{
  line-height: 30px;
}
.sub-title{
  font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  display: block; 
  background-color: transparent;
  margin-top: 40px;
  font-size: 30px;
  color: #f1ebe5;
  text-shadow: 0 8px 9px #c4b59d, 0px -2px 1px #fff;
  font-weight: 600;
  letter-spacing: 4px;
  margin: 50px;
}
  
 
  @media screen and (prefers-color-scheme: light) {
    .App {
      background-color: white;
      color: black;
     
    }
    .sub-title{
      font-size: 10px;
      font-weight: 100;
    }
  }

  @media screen and (max-width: 480px) {
    .App{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
}