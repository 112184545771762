/* Profile.css */

/* Basic reset for margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Mobile-first layout */
.compGetFunded,
.compBots,
.compSubSignal {
  padding: 20px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 30px;
}

.slider {
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  white-space: nowrap;
}

.slider > div {
  flex-shrink: 0;
  width: 85%;
  height: 400px;
  transition: transform 0.5s ease-in-out;
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin-right: 10px;
}

/* Common styling for sections */
.compSummary,
.compReview,
.compDescriptioj,
.compPlatforms,
.compMainPoints,
.compOurPlans {
  display: flex;
  padding: 10px;
}


/* Reviews or Plans */
.col-sm-2 {
  display: flex;
  flex-direction: column;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .compGetFunded,
  .compBots,
  .compSubSignal {
    padding: 10px;
  }


}
