/* DepositPlan.css */

.deposit-plan {
    border-radius: 13px;
    margin: 20px;
    margin-top: 70px;
    font: 1em/1.618 Inter, sans-serif;

    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: 30px;
    
    color: #224;
    
  }
  .deposit-plan .subscription-tier{
    margin-right: 20px;
    height: 450px;
  }
  .deposit-plan .subscription-tier h3{
    font-size: 20px;
  }
  .deposit-plan .subscription-tier .signup-btn{
    margin-left: 80px;
    top: 72%;
  }
  
  .card {
    max-width: 300px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    justify-content: space-between;
    
    max-width: 500px;
    height: 400px;
    padding: 35px;
    box-shadow: 0 0 4px 4px rgba( 255, 255, 255, 0.18 );
    border: none;
    border-radius: 20px;
    color: white;
    backdrop-filter: blur(15px);
    transition: transform 0.8s ease; 
    transform: scale(1); 

  }
  .card:hover{
    background-color: rgba(255, 255, 255, 0.158);
    color: black;
    

    transform: scale(1.1); 
  transition: 0.8s;
  
  }
  
  .card h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .card p {
    margin: 5px 0;
    margin: 10px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px !important;
    color: white;
    font-weight: 700;
    
  }
  .card:hover p{
    color:gold;

  }  
  .card:hover   .offer p{
    text-decoration: none;
    color: greenyellow;
  }



  
  .offer{
    width: 40%;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: -40px;
    top: -20px;
    margin-top: 40px;
    font-size: larger;
    background: rgba(0, 0, 0, 0.25);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-bottom-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: transparent;
  }
  .offer p{
    color: green;
    text-decoration: line-through;

  }
.card button{
    height: 40px;
    margin-left: 20px;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    width: 150px;
    border: none;
    border-radius: 15px;
    transition: 0.5s;
    transform: scale(1);

    
  }
  .card button:hover {
    background-color: transparent;
    border: 1px solid rgba(253, 255, 255, 0.24);
    color: white;
    cursor: pointer;
    transform: scale(1.1); 
    transition: 0.8s;

  }
  .card .content{
    border-bottom: 1px solid rgba(255, 255, 255, 0.26);
  }
 

 
  @media screen and (max-width: 768px) {
    .deposit-plan {
      flex-direction: column; /* Stack cards in a single column on smaller screens */
      align-items: center; /* Center-align cards */
    }
  
  .deposit-plan .subscription-tier .signup-btn{
    position: relative;
    top: 40px;
    margin-left: -20px;
  }



    .card {
      width: 90%; /* Adjust card width for better responsiveness */
      max-width: none; /* Remove max-width to allow cards to expand */
      margin-right: 0; /* Remove margin between cards */
      margin-bottom: 20px; /* Add spacing between cards */
    }
  
    .offer {
      right: auto; /* Reset positioning of the offer */
      top: auto;
      margin-top: 10px; /* Add margin to the offer */
      align-self: center; /* Center-align the offer within the card */
    }
  }