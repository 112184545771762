.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  background-color: rgba(0, 0, 0, 0.233);
  position: relative;
}

.homeBrand a{
  font-size: 30px;
  text-decoration: none;
  color: gold;
  font-weight: 800;
}

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.781);
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .container {
   min-width: 350px !important;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 0;
  }
  .icon-bars{
    width: 40px;
    height: 40px;
  }
  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
   
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin: 10px;
    border-radius: 20px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(60vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    margin: 10px;
    border-radius: 20px;
  }
  .nav-elements ul a {
    color: black;
  }
  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}