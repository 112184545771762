/* ForexEdu.css */

/* Styling for the forex education section */
.forex-edu {
    padding: 50px;
  }
  .forex-edu h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .forex-edu p{
    font-size: 17px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
  }
  
  /* Styling for tutorials */
  .tutorials,
  .glossary,
  .advanced-strategies {
    margin: 40px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }
  .tutorials h3,
  .glossary h3,
  .advanced-strategies h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .tutorials p,
  .glossary p,
  .advanced-strategies p {
     text-align: left;
     line-height: 30px;
     font-weight: 300;
     color: white;

  }

  .tutorials ul,
  .glossary ul,
  .advanced-strategies ul {
    list-style: none;
    margin-left: 20px;
  }
  
  .tutorials li,
  .glossary li,
  .advanced-strategies li {
    margin-bottom: 10px;
  }
  
  /* Add more styling as needed */
  @media screen and (max-width: 480px) {
    .forex-edu {
    
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;
      margin-top: 50px;
    }
    .tutorials,
    .glossary,
    .advanced-strategies {
      flex-direction: column;
      text-align: start;
      align-items: flex-start;
      width: 100%;
    }
 
    
  }