.registration-form {
    width: 500px;
    height: auto;
display: flex;
flex-direction: column;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 13px;
    align-items: center;
    padding: 20px;
 
    background-color: black;
    color: white;
  }
  @media screen and (prefers-color-scheme: light) {
    .registration-form {
      background-color: white;
      color: black;
    }
  }
  
  .registration-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .registration-form input[type='text'],
  .registration-form input[type='email'],
  .registration-form input[type='password'] {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 35px;
    justify-content: center;
    padding: 10px;
    margin-bottom: 25px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .registration-form button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 40px;
  }
  
  .registration-form button:hover {
    background-color: #45a049;
  }
  