.signalCards{
    display: flex;
    max-height: 400px;
    border-radius: 13px;
    padding: 10px;
    justify-content: center;
    color: white;

}

.card{
    width: 280px;
    height: 400px;
    padding: 10px;
    margin: 40px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 15px;
        

}
.mini-card{
    width: 200px;
    height: 200px;
    padding: 10px;
    margin: 40px;
    background-color: transparent;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;


    position: relative;
   
    background: rgba(238, 234, 234, 0.575);
    box-shadow: 0 15px 35px rgba(0,0,0,.2);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    backdrop-filter: blur(40px);
    
    border: solid 2px transparent;
    background-clip: padding-box;
    box-shadow: 0px 10px 10px rgba(46, 54, 68, 0.03);

    transition: transform 0.8s ease; 
    transform: scale(1); 

  }
  .mini-card:hover{
    background-color: white;
    color: rgba(0, 0, 0, 0.699);
    

    transform: scale(1.1); 
  transition: 0.8s;
  border-bottom: 1px solid rgba(255, 255, 0, 0.199);
  border-left: 1px solid rgba(255, 255, 0, 0.247);
  
  }
.mini-card h2, p{
    color:rgba(0, 0, 0, 0.479);
    letter-spacing: 2px;
    text-align: center;
    margin: 20px 0 10px;
    line-height: 1.3em;
}
.mini-card p{
    font-size: 16px;
    color: black;
}
.mini-card-gif{
    border: none;
    width: 90px;
    border-radius: 60px;
    margin-left: 25%;
    margin-top: 10px;
    box-shadow: 0 0 20px 20px rgba(0,0,0,.2);
}
.mini-card:hover .mini-card-gif{
box-shadow: 0 0 6px 6px rgba(255, 255, 255, 0.295);
border-bottom: 1px solid rgba(255, 255, 0, 0.192);

}
@media screen and (max-width: 768px) {
    .signalCards {
      flex-wrap: wrap; /* Allow cards to wrap to the next row */
    }
  
    .mini-card {
      width: calc(50% - 80px); /* Two mini-cards per row with spacing */
      margin: 20px;
    }
  }
  
  /* Media query for even smaller screens */
  @media screen and (max-width: 480px) {
    .mini-card {
      width: 40%; /* Single column on smaller screens */
      margin: 20px 10px;
    }
    .mini-card-gif{
        width: 50px;
    }
  }