/* HomePage.css */
.home-page{
  width: 100%;
  min-height: 600px;
  margin-top: 50px;

}
.banner-section{
    display: flex;
    
}
/* Styling for the hero section */
.hero-section {
  background-color: black; /* Dark background color */
  color: #fff; /* Light text color */
  padding: 50px;
  text-align: center;
  margin: 0 ;
  width: 100%;
    background-image: url('https://images.unsplash.com/photo-1654198340681-a2e0fc449f1b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #f2f2f2;
    border-radius: 15px;
    border-radius: 0 0 5px  200px;
  height: 600px;
  display: flex;
  flex-direction: column;

  
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}
.banner-Img{
  display: flex;
  margin: 10px;
  width: 60%;
}

.text-Home{
  display: flex;
  
  
  margin-top: 100px;
  margin-bottom: 70px;
  margin-left:50px;
}

.text-Content{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 30px;
  font-family: 'Tajawal', sans-serif;
  font-size: 25px;
  margin-top: 20px;
 

}
.text-Content p{
  font-size: 30px;
  color: white;
}
.text-Home2{
  background-color: white;
  
  align-items: flex-end;
  height: 55px;
  width: 40%;
  margin: 10px;
  border-radius: 13px;
  color: black;
  transition: transform 0.8s ease; 
  transform: scale(1); 
  display: flex;
  

}
.text-Home2:hover{
  transform: scale(1.1); 
  transition: 0.8s;
}
.text-Home2 p{
  background-clip: text;
  background-image: linear-gradient(to right, #a1a7ac, #2a1869, #ff00d2, #78df81);
  font-size: 23px;
  font-weight: 900;
  letter-spacing: calc(1em / 8);
  padding: calc(--stroke-width / 2);
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: calc(1em / 16);
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
  color: black;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, cursive;
  margin-left: 20px;

}
.explore{
  background-color: white;
  width: 30%;
  height: 55px;
  display: flex;
  text-align: center;
  transition: transform 0.8s ease; 
  transform: scale(1); 
  justify-content: center;
  padding: 5px;
  border-radius: 25px;
  cursor: pointer;
}
.explore:hover{
  transform: scale(1.1); 
  transition: 0.8s;
  background-color: transparent;
  color: white;
}

/* styles.css */
.custom-img {
  width: 10%; /* Adjust the width as needed */
  height: 100%; /* Maintain aspect ratio */
  border: 1px solid #ccc; /* Example border style */
  margin-left: 20px;
  border: none;
}
.explore-gif{
  width: 40px;
  height: 30px;
  border-radius: 30px;
  border: none;
  margin-top: 10px


}
.explore:hover .explore-gif{
  width: 40px;
  cursor: default;
}
.explore:hover .cta-button{
  color: white;
}
.cta-button {
  display: inline-block;
  background-color: transparent;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}
/* Styling for key features section */
.key-features {
  padding: 50px;
  background: transparent;
  margin: 10px;
  padding: 20px;

}
.feature{
  display: flex;

}
.feature-card {
  padding: 30px;
  margin: 50px;
  margin-bottom: 20px;
  border-radius: 13px;
  box-shadow: 0 0 4px 4px rgba( 255, 255, 255, 0.18 );
  min-height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  transition: transform 0.8s ease; 
  width: 500px;
  transform: scale(1); 

}
.feature-card:hover{
  transform: scale(1.1); 
transition: 0.8s;
}

.feature-card h3 {
  font-size: 50px;
  margin-bottom: 10px;
}
.feature-card p{
  color: rgba(255, 255, 255, 0.575);
}


.feature-card .explore{
  display: none;
}
.feature-card:hover .explore{
  display: flex;
  margin: 10px;
  width: 250px;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 0;
}







/* Styling for the newsletter signup form */
.newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.newsletter-form input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
  margin-right: 10px;
  background-color: transparent;
  color: white;
  font-size: 14px;
  text-transform: lowercase;
  height: 45px;
  margin: 40px;
  margin-right: 10px;

}
.newsletter-form ::placeholder{
  color: yellow;
  text-transform: none;
}

.newsletter-form button{
  height: 40px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  width: 100px;
  border: none;
}
.newsletter-form button:hover{
  background-color: #a1a7ac;
  color: rgb(1, 7, 22);
}
/* Add more styling as needed */
.newsletter-signup, .about-us, .contact {
  margin: 40px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  box-shadow: 0 0 4px 4px rgba( 255, 255, 255, 0.18 );  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.newsletter-signup{
  box-shadow: none;
  border: none;

}
.about-us{
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
  padding-top: 10px;
}
.about-us .about-us-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  margin-top: -200px;
}
.about-us .about-us-text h2{
  font-size: 40px;
  text-align: center;
}
.about-us .about-us-text p{
  text-align: start;
  line-height: 1.5em;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.788);
}

.about-us .explore{
  font-size: 20px;
  height: 40px;
  margin: 30px;
  margin-left: 20%;
  align-items: center;
  border: none;
  border: 1px solid rgba(255, 255, 255, 0.205);

}

.about-us .about-us-procedure{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;

}

.about-us-procedure .steps{
  margin-top: 20px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.219);
}
.about-us-procedure .steps p{
text-align: start;
color: rgba(255, 255, 255, 0.795);
}
.contact p{
  color: white;
}
.contact .social-media a{
  text-decoration: none;
  color: #78df81;
  margin-left: 20px;
}
.stepsGif{
  width: 80px;
  border-radius: 30px;
  background-color: white;
}


@media screen and (max-width: 768px) {
  .home-page {
    margin: 10px;
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
  }

  .banner-section {
    flex-direction: column;
    width: 97%;
    margin: 4px;
  }

  .hero-section {
    height: auto;
    padding: 20px;
    width: 100%;
    margin: 4px;
  }

  .hero-section h1 {
    font-size: 2rem;
  }
  .explore{
    width: 70%;
    align-items: center;
    text-align: center;
    margin-left: 50px;
  }

  .text-Home {
    font-size: 13px;
    width: 100%;
    color: #2a1869;
    display: flex;
    flex-direction: column;
  }
  .text-Home h1{
    font-size: 30px;
    text-align: start;
  }
  .text-Home2{
    width: 70%;

  }
  .text-Home2 p{
    font-size: 18px;
  }
  .custom-img{
    width: 15%;
  }

  .banner-section  .banner-offer-text {
    font-size: 20px;
    margin: 10px;
    width: 70%;
    color: white;
    text-shadow: none;

    letter-spacing: 5px;
  }

  .text-Content{
    width: 80%;
    margin-top: 15px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 23px;
    color: #78df81;
    text-align: start;
    align-items: flex-start;
  }
  .text-Content p{
    text-align: start;
    font-size: 22px;
  }

  .newsletter-signup{
    width: 70%;
  }
  .key-features{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .feature{
    display: flex;
    flex-direction: column;
    width: 70px;
    justify-content: center;
    align-items: flex-start;
  }
  .feature-card{
    width: 400px;
    margin-left: 40px;
  }
  .feature-card h3{
    font-size: 30px;
    text-align: start;

  }
  .feature-card .explore{
    display: flex;
    width: 250px;
    margin-top: 10px;
    height: 50px;
    
  }
  .newsletter-signup h2{
    font-size: 22px;
    width: 100%;
    margin-bottom: -40px;
  }

  .newsletter-form input[type="email"]{
    width: 250px;
  }
  .about-us{
    margin: 10px;
    box-shadow: none;
    border: none;
    display: flex;
    width: 90%;
    flex-direction: column;
  }

  .about-us .about-us-text{
    margin-top: 10px;
  }
  .about-us .explore{
    width: 70%;
  }
  .contact{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 10px;
  }
  /* Add more responsive styles for other elements */
}

@media screen and (max-width: 480px) {
  /* Styles for screens smaller than 480px */

  .home-page {
    margin: 10px;
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
  }

  .banner-section {
    flex-direction: column;
    width: 470px;
    margin: 4px;
  }

  .hero-section {
    height: auto;
    padding: 20px;
    width: 100%;
    margin: 4px;
  }

  .hero-section h1 {
    font-size: 2rem;
  }
  .explore{
    width: 70%;
    align-items: center;
    text-align: center;
  }

  .text-Home {
    font-size: 13px;
    width: 100%;
    color: #2a1869;
    display: flex;
    flex-direction: column;
  }
  .text-Home h1{
    font-size: 30px;
    text-align: start;
  }
  .text-Home2{
    width: 80%;
    margin-left: -20px;

  }
  .text-Home2 p{
    font-size: 15px;
  }
  .custom-img{
    width: 15%;
  }

  .banner-section  .banner-offer-text {
    font-size: 20px;
    margin: 10px;
    width: 70%;
    color: white;
    text-shadow: none;

    letter-spacing: 5px;
  }

  .text-Content{
    width: 80%;
    margin-top: 15px;
    font-size: 23px;
    text-align: start;
    align-items: flex-start;
  }
  .text-Content p{
    text-align: start;
    font-size: 22px;
  }

  .newsletter-signup{
    width: 70%;
  }
  .key-features{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .feature{
    display: flex;
    flex-direction: column;
    width: 70px;
    justify-content: center;
    align-items: flex-start;
  }
  .feature-card{
    width: 300px;
    margin-left: 10px;
  }
  .feature-card h3{
    font-size: 30px;
    text-align: start;

  }
  .feature-card .explore{
    display: flex;
    width: 200px;
    margin-top: 10px;
    height: 50px;
    
  }
  .newsletter-signup h2{
    font-size: 22px;
    width: 100%;
    margin-bottom: -40px;
  }

  .newsletter-form input[type="email"]{
    width: 200px;
  }
  .about-us{
    margin: 10px;
    box-shadow: none;
    border: none;
    display: flex;
    width: 90%;
    flex-direction: column;
  }

  .about-us .about-us-text{
    margin-top: 10px;
  }
  .about-us .explore{
    width: 70%;
  }
  .contact{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 10px;
  }
  /* Add more responsive styles for other elements */
}