body {
  
    font-family: 'Open Sans', sans-serif;
    line-height: 1.75em;
    font-size: 16px;
    background-color: #222;
    color: #aaa;
    margin: 0;
    background-image: url('https://images.unsplash.com/photo-1604342427523-189b17048839?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1527&q=80');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #f2f2f2;

  
}
  


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 16px;
  text-decoration: none;
}
h1{
  line-height: 34px;	
  font-weight: 900;

}

h1, h2, h3 {

  text-decoration: none
}
h3{

      border-bottom: none; 
    border-top: none;

}




