/* ChoosePlan.css */

/* Styling for the subscription plans section */
.choose-plan {
    padding: 50px;
  }
  
  .choose-plan h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Styling for subscription tiers */
  .subscription-tiers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .subscription-tier {
    box-shadow: 0 0 4px 4px rgba( 255, 255, 255, 0.18 );

    padding: 30px;
    width: calc(33.33% - 20px);
    text-align: center;
    border-radius: 15px;
    height: 500px;


  }
  .subscription-tier ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 13px;
    margin: 15px;
    text-align: start;
  }
  .subscription-tier h3 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .subscription-tier p {
    font-size: 17px;
    text-align: start;
    line-height: 30px;
    font-weight: 300;
    color: white;
  }
  .price{
    color: gold;

  }
  .price, .previous-price {
    font-size: 1.7rem;
    font-weight: 900;
    margin-top: 10px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .previous-price {
    font-size: 1.0rem;
    font-weight: 600;
    text-decoration: line-through;
    color: rgba(255, 255, 0, 0.5);

    font-style: italic;

  }
  .signup-btn{
    position: absolute;
    top: 96%;
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    padding: 20px;
  }
  
  .sign-up-button {
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    width: 150px;
    border: none;
    border-radius: 15px;
    transition: 0.5s;
    transform: scale(1);
    position: absolute;
    top: 100%;
    align-items: center;
    justify-content: center;
    margin-left: -60px;
  }
  .sign-up-button:hover {
    background-color: transparent;
    border: 1px solid rgba(253, 255, 255, 0.24);
    color: white;
    cursor: pointer;
    transform: scale(1.1); 
    transition: 0.8s;

  }
  .convincing{
    display: flex;
    justify-content: space-between;
  }
  
  /* Styling for convincing text */
  .convincing-text {
    font-size: 1.2rem;
    line-height: 30px;
    text-align: start;
    margin-top: 40px;
    padding: 20px;

  }
  .convincing-image{
    min-width: 55%;
  }
  /* Add more styling as needed */
  @media screen and (max-width: 768px) {
    .subscription-tier {
      width: calc(50% - 20px); /* Two columns on smaller screens */
    }
  }
  
  /* Media query for even smaller screens */
  /* Additional styles for smaller screens */
@media screen and (max-width: 480px) {
  
  .subscription-tiers {
    flex-direction: column; /* Stack subscription tiers in a single column */
    align-items: center; /* Center-align subscription tiers */
  }

  .subscription-tier {
  
    width: 300px; /* Full width for single column layout */
    margin-bottom: 20px; /* Adjust spacing between subscription tiers */
    height: 500px;
  }
  .subscription-tier p {
    font-size: 14px;
  }
  .subscription-tier ul{
    font-size: 12px;
    margin-left: 0;
  }


  .price{
    color: gold;
  }
  .price, .previous-price {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 10px;
   
  }
  .previous-price {
    font-size: 1.0rem;
    font-weight: 400;
   

  }




  .signup-btn{
    margin-top: 5px;
    margin-left: 20px;
  }
  .sign-up-button {

      display: block;
      height: 35px;
    width: 150px;
    justify-content: space-between;
    position: relative;
    top: 10px;
    margin-left: 50px;
  }
  .convincing {
    flex-direction: column; /* Stack convincing text below image on smaller screens */
    text-align: center; /* Center-align text within convincing section */
    width: 350px;
    margin-left: -25px;
  }

  .convincing-image {
    min-width: 100%; /* Ensure the image spans the full width */
    margin: 0 auto; /* Center-align the image */
  }

  .convincing-text {
    text-align: center; /* Center-align text within convincing section */
    padding: 10px; /* Adjust padding for better spacing */
  }



}
