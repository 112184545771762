


.trading-bots {
    padding: 50px;
    color: wheat;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    align-items: center;
  }

  .trading-bots  p{
    color: white;
    font-size: medium;
    margin: 30px;
    text-align: start;
    line-height: 30px;
    font-weight: 300;

  }
  
  .trading-bots h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .overviewSection,  .how-bots-work, .bot-strategies{
    margin: 40px;
    padding: 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
  }
  /* Styling for bots overview */
  .how-bots-work, .bot-strategies{
    flex-direction: column;

  }

  .bots-overview {
    width: 60%;
    margin-right: 20px;
    margin-top: 0;

  }
  
  .bots-overview h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .bots-overview p{
    line-height: 40px;
    font-size: 17px;
  }

  .bots-banner{
    
    border-top-right-radius: 13px;
    border-bottom-right-radius: 40px;
    background: url(https://images.unsplash.com/photo-1642790403805-a3835434499e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1495&q=80);
 }
  
  /* Styling for how bots work */
 
  
  .how-bots-work h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  /* Styling for bot strategies */
  
  
  .bot-strategies h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .bot-strategies ul {
    list-style: none;
    margin-left: 20px;
  }
  
  .bot-strategies li {
    margin-bottom: 10px;
  }
 .trading-bots .subscription-tier p{
    font-size: 16px;
    width: 96%;
  }

  .trading-bots .subscription-tier li{
    font-size: 12px;
  }
  .trading-bots .subscription-tier .sign-up-button{
    background-color: yellow;
    bottom: 0;
    
  }
  .btn-test{
    height: 70px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .btn-test button{
    
      height: 40px;
      margin-left: 20px;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      width: 150px;
      border: none;
      border-radius: 15px;
      transition: 0.5s;
      transform: scale(1);
  
      
    }
    .btn-test button:hover {
      background-color: transparent;
      border: 1px solid rgba(253, 255, 255, 0.24);
      color: white;
      cursor: pointer;
      transform: scale(1.1); 
      transition: 0.8s;
  
    }
@media screen and (max-width: 480px) {
  .trading-bots {
    display: flex;
    flex-direction: column;
  }
  .overviewSection,  .how-bots-work, .bot-strategies{
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
  }
  .bots-overview {
    width: 100%;
  }
  
}