/* ForexMarketOverview.css */

/* Styling for the forex market overview section */
.forex-market-overview {
    padding: 50px;
  }
  .forex-market-overview h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Styling for market information */
  .market-information,
  .trading-basics,
  .how-it-works,
  .live-market-data {
    margin: 40px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }
  
  .market-information h3,
  .trading-basics h3,
  .how-it-works h3,
  .live-market-data h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .market-information p,
  .trading-basics p,
  .how-it-works p,
  .live-market-data p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: start;
    line-height: 30px;
    color: white;

  }
  
  
  /* Add more styling as needed */
  @media screen and (max-width: 480px) {
    .forex-market-overview {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;
      margin-top: 50px;
    }
    .market-information,
    .trading-basics,
    .how-it-works,
    .live-market-data{
      flex-direction: column;
      text-align: start;
      align-items: flex-start;
      width: 100%;
    }
 
    
  }