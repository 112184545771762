
    


.banner {
  
  font-family:  -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  letter-spacing: 2px;

  font-size: 72px;
  color: #f1ebe5;
  text-shadow: 0 8px 9px #c4b59d, 0px -2px 1px #fff;
  font-weight: bold;
  letter-spacing: -4px;
  margin: 0;
  margin-bottom: 50px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}




.banner h3{
  font-size: 33px;
  margin: 50px;
  margin-left: 5px;
  margin-left: 0;
  letter-spacing: 4px;

  
}

.banner p{
  letter-spacing: 8px;
  text-shadow:none;
  margin-top: 20px;
  margin-left: 0;
  color: yellow;
  text-align: start;
  

}  
.banner-offer-text{
  display: flex;
  justify-content: space-between;
  color: gold;
  width: 50%;
  text-align: center;
  padding: 20px;
  font-size: 44px;
  margin-left: 50px;
}


.text span {
  font-size: 45px;
  font-weight: 700;
  padding: 0 10px;
  text-transform: uppercase;
  color: #fff;
  animation: zoomup 2s linear infinite;
  animation-delay: calc(200ms * var(--i));
  margin-top: 10px;
}

@keyframes zoomup {
  0%,
  100% {
    color: #00c2ba;
    filter: blur(1px);
    text-shadow: 0 0 10px #00c2ba, 0 0 20px #00c2ba, 0 0 30px #00c2ba,
      0 0 40px #00c2ba, 0 0 60px #00c2ba, 0 0 80px #00c2ba, 0 0 100px #00c2ba,
      0 0 120px #00c2ba;
  }

  5%,
  95% {
    filter: blur(0);
    color: #fff;
    text-shadow: none;
  }
}

/* Styles for screens smaller than 480px */
@media screen and (max-width: 480px) {
  .banner {
    margin: 0;
    font-size: 50px; /* Adjust font size for smaller screens */
    margin-bottom: 30px; /* Reduce margin for smaller screens */
    width: 85%;/* Center-align text on smaller screens */

  }

  .banner h3 {
    font-size: 24px; /* Adjust font size for smaller screens */
    margin: 20px 0; 
    text-align: start;
    text-decoration: none;
    font-weight: 200;
    font-size: 25px;
  }

  .banner p {
    font-size: 16px; /* Adjust font size for smaller screens */
    margin-top: 10px; /* Adjust margin for smaller screens */
    text-align: center; /* Center-align text on smaller screens */
  }

  .banner-offer-text {
    width: 80%; /* Adjust width for smaller screens */
    margin-left: auto; /* Center-align the offer text */
    font-size: 24px; /* Adjust font size for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
    text-align: center; /* Center-align text on smaller screens */
  }

  .text span {
    font-size: 24px; /* Adjust font size for smaller screens */
    margin-top: 5px; /* Adjust margin for smaller screens */
  }
}


